<template>
<div class="bg-white">
  <div class="container-fluid wrapper-small content-form">
    <div class="row">
      <div class="col-12">
        <div class="text-center mb-4">
          <h1 class="mb-3 anim-load1">
            Thank you
          </h1>
          <p class="lead anim-load2">
            Thank you for registering your interest. We'll be in touch soon.
          </p>
        </div>
      </div>
    </div>
  </div>

  <Footer />

</div>
</template>

<script>
export default {
  name: 'RegisterInterestThanks',
  created () {
    this.$store.state.loadingProgress = false
    this.$store.state.appSection = 'brochure'
  },
}
</script>
